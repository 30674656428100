export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [7,[2]],
		"/(app)/admin": [8,[2,3]],
		"/(app)/admin/settings": [9,[2,3]],
		"/auth": [29],
		"/(app)/c/[id]": [10,[2]],
		"/error": [30],
		"/(app)/interest": [11,[2,4]],
		"/(app)/interest/gotoRecharge": [12,[2,4]],
		"/(app)/interest/history": [13,[2,4]],
		"/(app)/playground": [14,[2,5]],
		"/s/[id]": [31],
		"/(app)/workspace": [15,[2,6]],
		"/(app)/workspace/documents": [16,[2,6]],
		"/(app)/workspace/functions": [17,[2,6]],
		"/(app)/workspace/functions/create": [18,[2,6]],
		"/(app)/workspace/functions/edit": [19,[2,6]],
		"/(app)/workspace/models": [20,[2,6]],
		"/(app)/workspace/models/create": [21,[2,6]],
		"/(app)/workspace/models/edit": [22,[2,6]],
		"/(app)/workspace/prompts": [23,[2,6]],
		"/(app)/workspace/prompts/create": [24,[2,6]],
		"/(app)/workspace/prompts/edit": [25,[2,6]],
		"/(app)/workspace/tools": [26,[2,6]],
		"/(app)/workspace/tools/create": [27,[2,6]],
		"/(app)/workspace/tools/edit": [28,[2,6]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';